import ActionService from "domain/shared/services/action-service";
import { RootState } from "domain/shared/store";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAsyncFn } from "react-use";

export type CommonListContent = {
  label: string;
  value: string;
  selected?: boolean;
}

export type SignDocsListContent = {
  items: CommonListContent[],
  bundles: any;
}

export const ActionTypes = {
  GetDocs: 'get-docs',
  EsignDocs: 'esign-docs',
  CCAuth: 'cc-auth',
  CsiAproval: 'csi-approval',
  CreditConsent: 'credit-consent',
  PurchaseVehicle: 'purchase-vehicle',
  FinanceInsurance: 'finance-and-insurance',
}

type Config = {
  action: string;
  onError?: (error: Error) => void;
  onNoData?: (message: string) => void;
}

function listActionApiStrategy(action: string, contact: any) {
  if (action === ActionTypes.GetDocs) return ActionService.getDocs();
  return ActionService.getActionDocs(contact.companies.uuid, action);
}

function actionMapperStrategy(data: any, action: string, setSelectedValue: React.Dispatch<React.SetStateAction<CommonListContent[]>>) {
  // TODO: transform both in the same
  if ([ActionTypes.CsiAproval, ActionTypes.CreditConsent].includes(action)) {
    const list = data.files_list;
    const defaultSelected = list.length === 1;

    const mapped = list.map((item: any) => (
      { label: item.name, value: item.id, selected: defaultSelected }
    )) as CommonListContent[];

    setSelectedValue(mapped);
    return mapped;
  }

 if ([ActionTypes.PurchaseVehicle, ActionTypes.EsignDocs, ActionTypes.FinanceInsurance].includes(action)) {
  const list = data.files_list;

  const items = list.map((item: any) => 
    ({ label: item.name, value: item.id })
  ) as CommonListContent[];

  const bundles = data?.form_config?.bundles || [];
  return { items, bundles } as SignDocsListContent;
 }

  const state = {
    [ActionTypes.GetDocs]: () => {
      const items = data.map((item: any) => ({ label: item.name, value: item.id })) as CommonListContent[];
      return items;
    },
    [ActionTypes.CCAuth]: () => {
      const items = data.files_list.map((item: any) => ({ label: item.name, value: item.id, selected: true })) as CommonListContent[];
      setSelectedValue(items);
      return items;
    },
  }

  return state[action]?.();
}

const DEFAULT_STATE = { value: [], loading: true, };
const ESIGN_DEFAULT_STATE = { loading: true, value: { bundles: [], items: [] } };

export default function useDocList(config: Config) {
  const { action, onError, onNoData } = config;
  const [selectedValue, setSelectedValue] = useState<CommonListContent[]>([]);
  const { activeOpportunity: contact } = useSelector((state: RootState) => state.opportunity);

  const isDocKey = useMemo(() => Object.values(ActionTypes).includes(action), []);

  const defaultState = useMemo(() => {
    if (!isDocKey) return { ...DEFAULT_STATE, loading: false };
    if (action === ActionTypes.EsignDocs) return ESIGN_DEFAULT_STATE;
    return DEFAULT_STATE;
  }, []);

  const [current, fetch] = useAsyncFn(async () => {
    const response = await listActionApiStrategy(action, contact);
    const items = actionMapperStrategy(response, action, setSelectedValue);
    return items;
  }, [], defaultState);

  const { value, loading, error } = current;
  const hasValue = Array.isArray(value) ? value.length : value?.items.length;
  const items = Array.isArray(value) ? value : value?.items;
  const bundles = Array.isArray(value) ? [] : value?.bundles;

  useEffect(() => {
    if (!isDocKey) return;
    if (!error) return; 
    onError?.(error);
  }, [error]);

  useEffect(() => {
    if (!isDocKey) return;
    if (loading) return;
    if (hasValue) return;
    const states = {
      [ActionTypes.GetDocs]: 'GetDocs',
      [ActionTypes.EsignDocs]: 'Esign',
      [ActionTypes.CCAuth]: 'CC-AUTH',
      [ActionTypes.CsiAproval]: 'CCI-Approval',
      [ActionTypes.CreditConsent]: 'Credit Consent',
      [ActionTypes.FinanceInsurance]: 'Finance And Insurance',
    };

    onNoData?.(`'There is currently no ${states[action]} Content available to be shared'`);
  }, [value, loading, action, isDocKey]);

  useEffect(() => {
    if (!isDocKey) return;
    fetch?.();
  }, [action, isDocKey]);

  return { items, selectedValue, bundles, loading, setSelectedValue };
}