import axios from 'domain/shared/services/axios';

import { ACTION_ENGINE } from 'env';
import {
  Address,
  Business,
  Contact,
  IOppSource,
  IOppType,
  IPropsOpp,
  IRelationship,
  Opportunity,
  Person,
  CreateOpportunityType,
} from '../types/opportunity';
import { IPeople } from '../types/people';
import { File as FSFile, UploadFile } from '../types/file.interface';
import { IS_WEB } from '../constants';
import fileSystemUploadFiles from '../graphql/fileSystemUploadFiles';
import { CreditAppData } from '../types/creditapp';
import { DetaislsData } from '../types/DetaislsData';
import { CoBuyerData } from '../types/CoBuyerData';
import Client from 'domain/shared/graphql/client';
import { queryLeadSourceById, queryLeadSourceByDescription, queryLeadSource, queryLeadSourceByIdAndDescription } from '../graphql/get-lead-source';
import { addLeadParticipant, createPeople } from '../graphql/participant';

export enum searchType {
  NAME = 1,
  EMAIL = 2,
  PHONE = 3,
  TITLE = 4,
}

type UploadFileType = Blob | Blob[] | File | File[] | UploadFile | UploadFile[];

class OpportunityService {
  static async searchOppByContact(contactId: number) {
    const { data } = await axios.get(`leads?q=(people_rs.id:${contactId})`);
    return data;
  }

  static async PeopleSearch(text: string, st: searchType) {
    let search = '';
    if (st === searchType.EMAIL) {
      search = `email_clean:${text.toLowerCase()}`;
    }
    if (st === searchType.NAME) {
      search = `name:${text.toLowerCase()}`;
    }
    if (st === searchType.PHONE) {
      search = `phone_clean:${text.replace(/\D/g, '')}`;
    }
    const url = `peoples?sort=name|asc&q=(${search}%)`;
    const { data } = await axios.get<IPeople[]>(url);
    return data;
  }

  static async getPeopleById(idNumber: number) {
    const url = `peoples?sort=name|asc&q=(id:${idNumber})`;
    const { data } = await axios.get<IPeople[]>(url);
    return data;
  }

  static async create(opportunity: Opportunity) {
    const { data } = await axios.post<Opportunity>('leads', opportunity, {
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  }

  static async update(opportunity: Opportunity, oppId: number) {
    const { data } = await axios.put<Opportunity>(`leads/${oppId}`, opportunity, {
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  }

  static async associateFileToOpp(files: File[], oppId: number, title: string, taskId?: number) {
    const { data } = await axios.put<Opportunity>(`leads/${oppId}`, { files, title, checklist_upload: taskId }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return data;
  }

  static async delete(oppId: number) {
    return axios.delete<Opportunity>(`leads/${oppId}`);
  }

  static async createAndAddParticipant(leadId: number, obj: any) {
    const contact: Contact[] = [];

    if (obj.phone) contact.push({ contacts_types_id: 3, value: obj.phone, });
    if (obj.email) contact.push({ contacts_types_id: 1, value: obj.email, });

    const createPeopleResponse = await Client.mutate({
      mutation: createPeople,
      variables: {
        input: {
          firstname: obj.firstname,
          middlename: obj.middlename,
          lastname: obj.lastname,
          contacts: contact,
          address: [],
        }
      }
    })

    if (!createPeopleResponse.data.createPeople) {
      throw new Error('Error creating person');
    }

    const peopleId = createPeopleResponse.data.createPeople.id
    const person = createPeopleResponse.data.createPeople

    if (peopleId) {
      const response = await Client.mutate({
        mutation: addLeadParticipant, variables: { input: { lead_id: Number(leadId), people_id: Number(peopleId), relationship_id: Number(obj.relationship) } },
      });

      if (response.errors) {
        throw new Error('Error adding participant');
      }
    }

    return {
      id: Number(peopleId),
      ...obj,
      custom_fields: person.custom_fields,
      language: 'EN',
      name: `${obj.firstname} ${obj.lastname}`,
    }
  }

  static async getById(id: number) {
    return axios.get<Opportunity>(`leads/${id}`);
  }

  static async getFiles(uuid: number) {
    const { data } = await axios.get<any>(`leads/${uuid}/messages/0/files`);

    if (!data) return;
    const { messages } = data as { messages: any[] };
    const list = messages.map<any[]>(({ files }: any) => files);

    const newList = list.reduce((prev, current) => {
      prev.push(...current);
      return prev;
    }, []);

    // eslint-disable-next-line consistent-return
    return newList;
  }

  static async mergeAllFiles(uuid: number, fileName: string) {
    const response = (await axios.get<any>(`leads/${uuid}/messages/0/files`)).data;
    const name = `${OpportunityService.getFileName(fileName)}`;
    if (response) {
      const { messages } = response;
      const fileList = messages.map(({ files }: any) => files);
      const list: any = [];
      fileList.filter((f: any) => {
        f.filter((i: any) => list.push(i.filesystem_id));
        return list;
      });
      const { url } = await (await axios.post<any>(`leads/${uuid}/messages/0/files-merge`, { files: list })).data;
      fetch(url)
        .then((res) => res.blob())
        .then((data) => {
          const urls = URL.createObjectURL(data);
          OpportunityService.download(urls, name);
          URL.revokeObjectURL(url);
        })
        .catch((err) => console.error(err));
    }
  }

  static async getOppTypes(): Promise<IOppType[]> {
    const response = (await axios.get<IOppType[]>('leads-types')).data;
    return response;
  }

  static async getActionData(lDID: string, action:string, noteParam: string)
    : Promise<CreditAppData | DetaislsData | CoBuyerData> {
    const { data } = await axios.get<CreditAppData | DetaislsData | CoBuyerData>(
      `${ACTION_ENGINE}/lead-engagement-submission/${lDID}/${action}${noteParam}`,
    );
    return data;
  }

  static async getOppSources(description?: string, id?: string): Promise<IOppSource[]> {
    const params = {
      ...description ? { q: `(description:${description})` } : {},
    };
    const { data } = (await axios.get<IOppSource[]>('leads-sources', { params }));
    return data;

    // const query = description ? queryLeadSourceByDescription : queryLeadSource;
    // const response = await Client.query<{ leadSources: { data: IOppSource[] } }>({
    //   query, variables: { description, id },
    // });

    // return response.data.leadSources.data;
  }

  static async getOppSourceById(id: number, description?: string): Promise<IOppSource | undefined> {
    const query = description ? queryLeadSourceByIdAndDescription : queryLeadSourceById;
    const response = await Client.query<{ leadSources: { data: IOppSource[] } }>({
      query, variables: { id, description },
    });

    return response.data.leadSources.data?.[0];
  }

  static async getOppSourcesById(idNumber: number) {
    const url = `leads-sources?sort=name|asc&q=(id:${idNumber})`;
    const { data } = await axios.get<IPeople[]>(url);
    return data;
  }

  static async getRelationship(): Promise<IRelationship[]> {
    const muckList: IRelationship[] = [
      { id: '3', name: 'Child' },
      { id: '11', name: 'Co-worker' },
      { id: '10', name: 'Employee' },
      { id: '1', name: 'Employer' },
      { id: '6', name: 'Friend' },
      { id: '9', name: 'Nearest relative' },
      { id: '7', name: 'Other' },
      { id: '5', name: 'Other relative' },
      { id: '4', name: 'Parent' },
      { id: '8', name: 'Referral' },
      { id: '2', name: 'Spouse' },
      { id: '1', name: 'Co-buyer' },
    ];
    // const response = (await axios.get<IRelationship[]>('relationship')).data;
    return muckList;
  }

  static getFileName = (filename: string) => {
    const today = new Date(Date.now()).toISOString().split('T')[0];
    const name = `${filename}_Jacket_${today.replaceAll('-', '')}`;
    return name;
  };

  static async unSyncELeads(uid: string) {
    await axios.delete(`/leads/${uid}/desync`);
  }

  static download = (path: string, filename: string) => {
    if (IS_WEB) {
      // @ts-ignore
      const DOCUMENT = document;
      // Create a new link
      const anchor = DOCUMENT.createElement('a');
      anchor.href = path;
      anchor.download = filename;

      // Append to the DOM
      DOCUMENT.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      DOCUMENT.body.removeChild(anchor);
    }
  };

  static async uploadPhoto(files: UploadFileType, fieldName: string = 'files', name?: string): Promise<FSFile[]> {
    const formData = new FormData();
    if (Array.isArray(files)) {
      files.forEach((item, index) => {
        // @ts-ignore
        formData.append(`${fieldName}-${index}`, item);
      });
    } else {
      // @ts-ignore
      formData.append(fieldName, files, name);
    }

    const { data } = await axios.request<FSFile[]>({
      method: 'POST',
      url: 'filesystem',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    return data;
  }

  static async uploadFilesMobile(files: UploadFile[]): Promise<FSFile[]> {
    const file = await fileSystemUploadFiles(files);
    if (!file) {
      throw new Error('Error uploading file');
    }
    return [file] as FSFile[];
  }

  static opportunityMapper(obj: IPropsOpp): CreateOpportunityType {
    const address: Address[] = [
      {
        address: obj.address1,
        address_2: obj.address2,
        city: obj.city,
        state_id: obj.state_id,
        zip: obj.zipcode,
        is_default: 1,
        countries_id: 2,
      },
    ];

    const business: Business = {
      name: obj.business_name,
    };

    const contact: Contact[] = [
      {
        contacts_types_id: 3,
        value: obj.phone_number,
      },
      {
        contacts_types_id: 1,
        value: obj.email,
      },
    ];

    const person: Person = {
      id: obj.people_id || 0,
      first_name: obj.first_name,
      middlename: obj.middlename,
      last_name: obj.last_name,
      contacts: contact,
      address,
    };

    const opp = {
      costumer_type_id: obj?.costumer_type_id,
      business: business.name ? business : {} as Business,
      title: obj?.title,
      Source: 'APP',
      leads_owner_id: obj.leads_owner_id,
      leads_types_id: obj.leads_types_id,
      leads_sources_id: obj.leads_sources_id,
      people: person,
      language: 2,
      follower: obj.follower,
      participants: obj.participants,
      manager_id: obj.manager_id,
      get_docs_drivers_license: obj?.get_docs_drivers_license,
      driver_license_images: obj?.driver_license_images,
      id_verification: obj?.id_verification,
      description: obj.description,
    };

    return opp;
  }
}

export default OpportunityService;
