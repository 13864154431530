import React, { useCallback, useState, useEffect } from "react";
import * as Yup from 'yup';
import styled from "styled-components";
import Toast from "react-native-toast-message";
import { Platform } from "react-native";
import { getBottomSpace, isIphoneX } from "react-native-iphone-x-helper";

import useWindowSize from 'domain/shared/hooks/useWindowSize';

import { useFormik } from "formik";
import { Flex, Spacer } from "@kanvas/andromeda";
import { FormLabel, TextInputWithLabel } from "components/molecules/input-with-label";

// Atoms
import ModalContainer from 'atoms/ModelOption';
import DropDown from "components/atoms/dropdown-input";
import ButtonWithIcon from "components/atoms/button-with-icon";

// Utils
import getPeopleRelationships from 'domain/shared/utils/getPeopleRelationships';
import { IS_WEB } from "domain/shared/constants";

// Styles
import Colors from 'theme/colors';
import { translate } from "locales";

// Types
import { ListItem } from "domain/create-opportunity/add/component/types";
import { TextTransform } from "react-native-localized-text";
import OpportunityService from "domain/shared/services/opportunity-service";

interface IProps {
  visible: boolean;
  onClose: () => void;
  showModalMsg: boolean;
  onMsgClose: () => void;
  CustomModalMsg: any;
  MsgIcon: any;
  onSubmit?: (participant: any) => void;
  leadId: number
}

const InputContainer = styled(Flex)`
  background-color:${Colors.WHITE};
  height: 70px;
`;

const ButtonContainer = styled(Flex) <{ width: number }>`
  margin-horizontal: 16px;
  position: absolute;
  bottom: 0px;
  width: ${({ width }) => (width)}px;
  background-color: ${Colors.TRANSPARENT};
  border-radius: 8px;
  margin-bottom: 15px;
  ${IS_WEB ? 'box-shadow: 0px 0px 12px #00000040;' : ''} 
`;

const Container = styled(Flex)`
  margin-bottom: ${Platform.OS === 'android' ? '30' : isIphoneX() ? getBottomSpace() : 40}px;
`;

const initialParticipant = {
  firstname: '',
  middlename: '',
  lastname: '',
  phone: '',
  email: '',
  relationship: 13,
}

const phoneRegExp = /^\(\d{3}\)-\d{3}-\d{4}$/;

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("fieldIsRequired"),
  lastname: Yup.string().required("fieldIsRequired"),
  phone: Yup.string().trim().matches(phoneRegExp, "invalidPhone").required("fieldIsRequired"),
  email: Yup.string().email("invalidEmail"),
});

const AddParticipantModal = (props: IProps) => {
  const { onClose, visible, CustomModalMsg, MsgIcon, onMsgClose, showModalMsg, leadId, onSubmit = () => {} } = props;

  const [relationship, setRelationship] = useState<ListItem[]>([]);

  const [width] = useWindowSize();

  const formik = useFormik({
    validationSchema,
    validateOnChange: false,
    onSubmit: async (value: typeof initialParticipant, { setSubmitting }) => {
      try {
        if (!leadId) throw new Error('Lead ID is required');

        const participant = await OpportunityService.createAndAddParticipant(leadId, value);

        onSubmit(participant)
      } catch (err) {
        console.log(err)
        setSubmitting(false);
        Toast.show({
          text1: translate('fetchError'),
          type: 'error',
        })
      }
    },
    initialValues: initialParticipant,
  });

  const closeModal = () => {
    onClose();
    formik.resetForm();
  }

  const { values, handleChange, errors, handleSubmit, setFieldValue, isSubmitting, validateForm } = formik;

  const getRelationship = useCallback(async () => {
    try {
      const list: ListItem[] = (await getPeopleRelationships()).map(({ id = '0', name = '' }) => ({
        value: id,
        label: name,
        key: id,
      }));
      setRelationship(list);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error.messageFound);
    }
  }, []);

  useEffect(() => {
    getRelationship();
  }, []);

  return (
    <ModalContainer
      onPressClose={closeModal}
      isVisible={visible}
      titleText={translate("addParticipant")}
      showMsg={showModalMsg}
      customText={CustomModalMsg}
      icon={MsgIcon}
      onMsgClose={onMsgClose}
    >
      <Container>
        <Flex style={{ marginTop: 6 }} paddingHorizontal={16}>
          <TextInputWithLabel
            label="firstName"
            type="input"
            value={values.firstname}
            handleChange={handleChange('firstname')}
            required
            error={errors.firstname}
          />

          <TextInputWithLabel
            label="middleName"
            type="input"
            value={values.middlename}
            handleChange={handleChange('middlename')}
          />

          <TextInputWithLabel
            label="lastName"
            type="input"
            value={values.lastname}
            handleChange={handleChange('lastname')}
            required
            error={errors.lastname}
          />

          <TextInputWithLabel
            label="mobile"
            type="input-mask"
            mask="(999)-999-9999"
            value={values.phone}
            handleChange={handleChange('phone')}
            error={errors.phone}
            required
          />

          <TextInputWithLabel
            type="input"
            label="email"
            value={values.email}
            handleChange={handleChange('email')}
            error={errors.email}
          />

          <InputContainer style={{ marginVertical: 5 }}>
            <FormLabel text={translate('relationship')} required />
            <DropDown
              placeHolder={translate('relationship')}
              value={values.relationship.toString()}
              items={relationship}
              onSelectItem={(item: any) => setFieldValue('relationship', item.value)}
            />
          </InputContainer>

          <Spacer size={32} />

          <ButtonContainer
            width={width - 32}
          >
            <ButtonWithIcon
              textColor={Colors.WHITE}
              color={Colors.ORANGE}
              onPress={async () => {
                const errorList = await validateForm();
                if (!errorList) {
                  handleSubmit();
                }
              }}
              text={translate('save', TextTransform.CAPITALIZE)}
              disabled={isSubmitting}
              loading={isSubmitting}
              enabled
            />
          </ButtonContainer>
        </Flex>

        {
          IS_WEB && (
            <Spacer size={16} />
          )
        }
      </Container>
    </ModalContainer>
  )
}

export default AddParticipantModal;