import { Flex, Spacer } from '@kanvas/andromeda';
import { useNavigation, useRoute } from '@react-navigation/native';
import { FormLabel, TextInputWithLabel } from 'components/molecules/input-with-label';
import PreviewHeader from 'components/molecules/preview-header';
import { IS_MOBILE, IS_WEB } from 'domain/shared/constants';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import useForms from 'domain/shared/hooks/useForms';
import { Body3 } from 'components/molecules/text';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { RectButton } from 'components/atoms/touchable';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { translate } from 'locales';
import colors from 'theme/colors';
import { Platform } from 'react-native';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { useSelector } from 'react-redux';
import { RootState } from 'domain/shared/store';
import CustomFieldsApi from 'domain/shared/services/custom-fields';
import { showSuccess } from 'domain/shared/utils/toast';
import DropDown from 'components/atoms/dropdown-input';
import DateTimeInput from 'components/atoms/datetime-picker';
import moment from 'moment';
import wait from 'domain/shared/utils/wait';

const PREFILL_SIGN_DOCS = 'prefill-sign-docs';

const Container = styled(Flex)`
    background-color:${Colors.WHITE};
`;

const ContainerCheck = styled(Flex)`
  padding: 16px;
  flex-direction: row;
  width: 100%;
`;

const Button = styled(RectButton)`
  width: 100%;
`;

const ButtonContainer = styled(Flex)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: ${colors.WHITE};
  ${IS_MOBILE && `
    padding-horizontal: 12px;
    padding-bottom: ${getBottomSpace()}px
    padding-top: ${Platform.OS === 'ios' ? 10 : 0}px;
    height: ${Platform.OS === 'android' ? 90 : 100}px;
  `}
  ${IS_WEB && `
    position: fixed; z-index: 999; 
    box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER};
    padding: 20px 16px;
  `};
`;

const capitalizedText = (text: string = '') => text.trim().replace(/^\w/, (c) => c.toUpperCase());

interface ICheckItem {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  text: string,
  propertyName: string,
  value: boolean,
  required?: boolean,
  options: {label:string, value: any}[],
}

const CheckItem = (props: ICheckItem) => {
  const {
    text,
    setFieldValue,
    propertyName,
    options,
    value,
    required = false,
  } = props;

  const [selected, setSelected] = useState(value);

  const iconName = selected ? 'check-box' : 'check-box-outline-blank';
  const iconColor = selected
    ? Colors.DARK_ORANGE : Colors.SECONDARY_TEXT;

  const handle = () => {
    setFieldValue(propertyName, !selected ? options?.[0].value : options?.[1].value);
    setSelected(!selected);
  };

  const transformedText = useMemo(() => {
    if (!required) return text;
    return `${text}*`
  }, [text, required]);

  useEffect(() => setSelected(value), [value]);

  return (
    <ContainerCheck>
      <Flex flex={1} row align="center">
        <Button
          onPress={handle}
        >
          <Flex row>
            <Icon name={iconName} color={iconColor} size={24} />
            <Spacer horizontal size={4} />
            <Body3 text={transformedText} color={Colors.SECONDARY_TEXT} />
          </Flex>
        </Button>
      </Flex>
      <Flex
        row
        justify="flex-end"
        align="center"
      />
    </ContainerCheck>
  );
};

const DocumentForm = () => {
  const [fieldList, setFieldList] = useState([]) as any;
  const [title, setTittle] = useState([]) as any;
  const [loading, setLoading] = useState(false);

  const { forms, setForms }: any = useForms();
  const route = useRoute();
  const navigation = useNavigation();
  const activeOpportunity = useSelector((state: RootState) => state.opportunity.activeOpportunity)!;

  const initial = (route.params as any)?.prefillSingDocs;

  const { linkedFills = {} } = route.params as any;

  const initialValues = useMemo(() => {
    const { id = undefined } = route.params as any;


    if (!id) return {};

    let currentForm = {} as any;
    fieldList.map((item: any) => {
      const { type, attrs, prop_name } = item;
      const value = type === 'currency' ? linkedFills[attrs.linked_field] || 0.00 : linkedFills[attrs.linked_field];
      if (value === null || value === false) return;
      currentForm[prop_name] = value;
    });

    return { ...currentForm, ...forms[id], ...initial }
  }, [route.params, initial, forms, fieldList]);

  const setValuesInitial = useCallback(async () => {
    if (!route) return;
    if (!route.params) return;

    const { fields = {}, title: formTitle = '' } = route.params as any;

    setFieldList(fields);
    setTittle(formTitle);
  }, [route]);

  const addForm = useCallback((values: any) => {
    const { id }: any = route.params;
    const newForm = { ...forms };
    newForm[id] = values;
    
    setForms(newForm);

    navigation.goBack();
  }, [forms, route]);

  const Form = useCallback(({ item, formik, linkedFills = {} }: any) => {
    const { type, label, attrs, prop_name, options = [], required } = item;
    const { values, handleChange, setFieldValue } = formik;

    const currentValue = values[prop_name];

    const getValue = () => {
      if (currentValue) return currentValue;
      return linkedFills[attrs.linked_field];
    };

    const props = {
      required,
      key: prop_name,
      value: getValue(),
      text: capitalizedText(label),
      labelText: capitalizedText(label),
      label: capitalizedText(label),
    }; 

    const fields: Record<string, () => JSX.Element> = {
      'input': () => (
        <TextInputWithLabel
          {...props}
          type="input"
          handleChange={handleChange(`['${prop_name}']`)}
          keyboardType={attrs.type === 'number' ? 'numeric' : 'default'}
        />
      ),
      'email': () => (
        <TextInputWithLabel
          {...props}
          type="input"
          handleChange={handleChange(`['${prop_name}']`)}
          keyboardType="email-address"
        />
      ),
      'number': () => (
        <TextInputWithLabel
          {...props}
          type="input"
          keyboardType="numeric"
          handleChange={handleChange(`['${prop_name}']`)}
        />
      ),
      'currency': () => (
        <TextInputWithLabel
          {...props}
          type="currency"
          keyboardType="numeric"
          handleChange={(value: string) => setFieldValue(`['${prop_name}']`, value)}
        />
      ),
      'masked-input': () => (
        <TextInputWithLabel
          {...props}
          type="input-mask"
          mask={attrs?.mask}
          keyboardType="numeric"
          handleChange={handleChange(`['${prop_name}']`)}
        />
      ),
      'datepicker': () => {
        const { value } = props;
        const dateValue = value ? moment(value, 'MM-DD-YY').format("YYYY-MM-DD") : '';

        return (
          <DateTimeInput
            {...props}
            value={dateValue}
            placeholder="MM-DD-YY"
            onChange={(value) => setFieldValue(`['${prop_name}']`, value)}
          />
        )
      },
      // 'datepicker': () => (
      //   <TextInputWithLabel
      //     type="date"
      //     {...props}
      //     mask={attrs?.mask}
      //     handleChange={(value: string) => {
      //       console.log(value);
      //       setFieldValue(`['${prop_name}']`, value);
      //     }}
      //   />
      // ),
      'option-group': () => {
        if (attrs.multiple === false) {
          return (
            <Flex>
              <FormLabel text={capitalizedText(label)} required />
              <DropDown
                items={options}
                placeHolder=''
                value={getValue()}
                onSelectItem={(item: any) => setFieldValue(`['${prop_name}']`, item?.value)}
              />
            </Flex>
          );
        }

        return (
          <CheckItem
            {...props}
            options={options}
            setFieldValue={setFieldValue}
            text={capitalizedText(label)}
            propertyName={`['${prop_name}']`}
            value={String(getValue()).toLocaleLowerCase() === 'yes'}
          />
        );
      },
    }

    return fields[type]?.();
  }, [route.params]);

  const FormList = useCallback(({ formik }: any) => {
    if (fieldList.length <= 0) return null;

    return fieldList.map((item: any) => (
      <Flex padding={10} gap={10} key={item.prop_name}>
        <Form item={item} formik={formik} linkedFills={linkedFills} />
      </Flex>
    ))
  }, [fieldList, route, linkedFills]);

  const handleSave = async (values: any) => {
    try {
      setLoading(true);

      await CustomFieldsApi.set({
        data: values,
        entity_id: activeOpportunity.uuid,
        name: PREFILL_SIGN_DOCS,
      });

      showSuccess(translate('editSave'));
      (route.params as any)?.refresh();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      addForm(values);
    }
  };

  useEffect(() => {
    setValuesInitial();
  }, [route]);
 
  return (
    <Flex flex={1} style={{ backgroundColor: Colors.WHITE }}>

      <Formik onSubmit={() => {}} initialValues={initialValues} enableReinitialize>
        {
          ({ handleSubmit, ...formik }) => (
            <Flex flex={1}>
              <PreviewHeader
                actionName={title || ''}
                style={{ backgroundColor: Colors.BACKGROUND }}
              />
              <ScrollView
                contentContainerStyle={{
                  paddingBottom: 350,
                  ...IS_WEB ? {
                    paddingHorizontal: 20,
                    paddingTop: 20,
                  } : {},
                }}
                nestedScrollEnabled
              >
                <Container style={{ backgroundColor: Colors.WHITE, flex: 1 }}>
                  <FormList formik={formik} />
                </Container>
                
              </ScrollView>

              <ButtonContainer>
                <Flex>
                  <ButtonWithIcon
                    loading={loading}
                    onPress={() => handleSave(formik.values)}
                    text={translate('saveAndBack')}
                  />
                </Flex>
              </ButtonContainer>
            </Flex>
          )
        }
      </Formik>
    </Flex>
  );
};

export default DocumentForm;
