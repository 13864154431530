export default {
  hello: 'hello',
  addOpp: 'add new opportunity',
  editOpp: 'Edit Opportunity',
  firtName: 'first name',
  lastName: 'last name',
  oppTitle: 'opportunity title',
  mobile: 'mobile',
  email: 'email',
  typeCostumer: 'Type of Customer',
  phoneNumber: 'phone number',
  preferredLanguage: 'preferred language',
  addressLine1: 'address line 1',
  addressLine2: 'address line 2',
  address: 'address',
  state: 'state',
  city: 'city',
  zipCode: 'zip code',
  addParticipant: 'Add a Participant',
  addFollower: 'Add a Follower',
  requiredContactMessage: 'at least one of mobile or email needs to be filled.',
  saleInfo: 'sales info',
  owner: 'owner',
  country: 'country',
  selectOwner: 'owner',
  Follower: 'follower',
  language: 'Preferred Language',
  follower: 'follower',
  name: 'name',
  save: 'save',
  peopleFoundMessage: 'We found similar contacts with the same',
  opporunityAssociated:
    'There’s an open opportunity associated with this customer. Opportunity owner is',
  // eslint-disable-next-line @typescript-eslint/quotes
  opporunityAssociatedOwner: `There's an open opportunity associated with this customer. Opportunity owner is {owner}. Please contact {owner} regarding that opportunity.`,
  goToOpp: 'Go to the Open Opportunity',
  openOpp: 'Open Opportunity',
  confirm: 'Confirm',
  createOpportunity: 'Create New opportunity',
  Organization: 'Organization',
  Name: 'name',
  reporting: 'reporting',
  notificationSettings: 'notifications settings',
  passwordAndPrivacy: 'password & privacy',
  preferences: 'preferences',
  switchStore: 'switch store',
  logOut: 'log out',
  feedback: 'feedback',
  firstName: 'first name',
  displayName: 'display name',
  intro: 'intro',
  profile: 'profile',
  invalidPhone: 'Invalid Phone',
  photoGallery: 'photo gallery',
  camera: 'camera',
  cancel: 'cancel',
  userUpdateSuccessfully: 'user info updated successfully',
  logoutWarn: 'are you sure?',
  appSettings: 'app settings',
  endVisitCRM: 'please end the visit in your CRM',
  opportunitySource: 'Opportunity Source',
  opportunityType: 'Opportunity Type',
  source: 'Opportunity Source',
  type: 'Opportunity Type',
  participants: 'participants',
  participant: 'participant',
  businessName: 'Business Name',
  saveAndBack: 'Save and Back',
  seeMore: 'see more',
  searchingDatabase: 'Don’t see what you’re trying to find? We’re searching for more results in the database.',
  searchNotFound: 'Search complete, no more result found.',
  oppsNoFound: 'Oops! Something went wrong. Please try again.',
  notificationNotFound: 'No notifications yet. Stay tuned!',
  add_social_media: 'add social media',
  social_media: 'social media',
  google: 'google',
  facebook: 'facebook',
  selectReview: 'Select a Review',
  preview: 'Preview',
  currentPassword: 'current password',
  newPassword: 'new password',
  confirmNewPassword: 'confirm password',
  passwordChangedSuccessfully: 'password changed successfully',
  deleteYourAccount: 'delete your account',
  deleteAccount: 'delete account',
  back: 'back',
  delete: 'delete',
  changePassword: 'change password',
  deleteAccountConfirmationMessage: 'Deleting your account would remove all information related to this account and can’t be retrieved. Are you sure you want to proceed?',
  accountDeletedSuccessfully: 'Your account has been deleted!, you will be logout',
  preference: 'preference',
  selectStore: 'Select a Store',
  go: 'Go',
  welcomeUser: 'Welcome {{user}}',
  switchToStore: 'Switched to {{store}} Store',
  notSave: 'Could\'t save you changes try again later',
  fetchError: 'An error occurred try again later',
  ok: 'Ok',
  popupHasBeenBlocked: 'Pop-ups have been blocked. Please disable pop-up blocking on your browser settings',
  selectAtLeastOneFile: 'Select at least one file to proceed',
  selectAtLeastOneProduct: 'Select at least one vehicle to proceed',
  replayMessage: 'Hi {contactName}, this is {user} from {store}. I am following up with you about {action} I shared with you earlier. Please click the link below to {oldMessage} {url}',
  text: 'text',
  copy: 'copy',
  shareProduct: 'share vehicle',
  productInfo: 'product info',
  select: 'select',
  accountEmail: 'account email',
  contactEmail: 'contact email',
  workPhone: 'work phone',
  telephone: 'telephone',
  phone: 'phone',
  inShowroom: 'In Showroom',
  editSave: 'Changes have been saved',
  ProfileMessagetooltip: 'The intro is showing on the Digital Card to your customers',
  noOppRecordFound: 'This customer record does not have an open opportunity.',
  showAllDocs: 'Show All Docs',
  selectFromBundles: 'Select from bundles',
  unableToScanDL: 'Unable to scan, try again or enter information manually',
  copyMessage: 'Copy message',
  uploadMessage: ' has been uploaded.',
  chooseAction: 'Choose an action ',
  requiredPhoneOrEmail: 'Please provide phone number OR email',
  noResult: 'No Result.',
  accountUsername: 'account username',
  addNewOpp: 'Add a New Opportunity',
  viewFiles: 'view files',
  upload: 'upload',
  takeAnother: 'take another',
  done: 'done',
  filter: 'filter',
  selectMultiple: 'select multiple',
  uploading: 'Securely uploading...',
  mergingFiles: 'Securely merging files...',
  relationship: 'Relationship',
  leadIntegratedCRM: 'Lead stage has been integrated with the CRM',
  inventory: 'inventory',
  notifications: 'notifications',
  retake: 'retake',
  fieldRequired: 'Required fields are missing.',
  searchOpp: 'Enter a name, phone number or email',
  connectingWithCrm: 'Connecting with {crm} database...',
  identifyActiveOpportunities: 'Identifying active opportunities in {crm} database...',
  lookingForSearchTerm: 'Looking for {lead} in {crm} database...',
  activeOpp: 'active opportunities',
  connectingDatabaseInterrupted: 'Connection with {crm} database was interrupted. Please try again.',
  searchActiveUsers: 'of all active leads scanned',
  retry: 'Retry',
  addANewOpp: 'Add a New Opportuntiy',
  sorting: 'Sorting',
  lastUpdate: 'Last Updated (Default)',
  mostRecent: 'Most Recent Added',
  asc: 'Alphabetical (A-Z)',
  desc: 'Alphabetical (Z-A)',
  deleteOppConfirmation: 'Do you want to delete the opportunity?',
  oppClose: 'The opportunity has been recently closed',
  open: 'Open',
  myOpps: 'My Opps',
  visiting: 'Visiting',
  syncMessageSuccess: 'The opportunity has been successfully synced with eLead record.',
  matchListMessage: 'We found the following opportunities as a possible match. Please select the right one for instant syncing.',
  syncTooltipMessage: 'You can edit linked opportunity here.',
  syncTitle: 'Link and Sync Opportunity',
  tooltipMessage: 'You can edit synced opportunity here.',
  smartRecomendation: 'Smart Recommendation',
  search: 'Search',
  primarySales: 'Primary Sales',
  uptype: 'Uptype',
  salesStatus: 'Sales Status',
  synchronizing: 'Synchronizing',
  dontSeeLatestOpp: 'Don’t see your latest opportunity here? Please search',
  openCRM: 'Open in CRM and copy message ',
  middleName: 'Middle Name',
  showLessFields: 'Show less fields',
  showMoreFields: 'Show More Fields',
  closeTitle: 'Visit Closed Opportunity',
  closedText: 'We think you are looking for a closed opportunity. Select the right one to share widgets and access data.',
  closedOpp: 'Last Update Date',
  BannerMessage: '{rooftop} - {email} - {user} clicked on the {Banner} Manual Push Banner for {leadName}.',
  comment: 'comment',
  update: {
    title: 'SalesAssist needs an update',
    description: 'To use this app, download the latest version.',
    button: 'Update',
  },
  opportunities: 'opportunities',
  digitalJacket: '{customername}\'s Digital Jacket',
  'upload-docs': {
    insurence: 'Insurance',
    dl: 'Drivers License',
    'trade-in-title': 'Trade-in Title',
    w2: 'W2',
    'pay-stub': 'Pay Stub',
    'bank-statement': 'Bank Statement',
    ssn: 'SSN',
    receipt: 'Receipt',
    'proof-residence': 'Proof of Residence',
    'employment-letter': 'Employment Letter',
    id: 'ID',
    'total-loss': 'Total Loss',
    'draft-check': 'Draft Check',
    'otd-price': 'OTD Price',
    'proof-pension': 'Proof of Pension',
    'awards-letter': 'Awards Letter',
    'trade-in-registration': 'Trade-in Registration',
    'score-disclosure': 'Score Disclosure',
    'tag-registration': 'Tag Registration',
  },
  copyMessageEmail: 'Account email copied to clipboard.',
  closedTextNotFound: 'The customer record does not have an open opportunity.',
  pushNotificationSchedule: 'Push Notification Schedule',
  reset: 'reset',
  condition: 'Condition',
  bodyType: 'Body Type',
  milage: 'Mileage',
  price: 'Price',
  apply: 'Apply',
  milageSort: '{{min}} - {{max}} Miles',
  // eslint-disable-next-line no-template-curly-in-string
  priceSort: '${{min}} - ${{max}}',
  defaultSort: '{{min}} - {{max}}',
  seeResults: 'See Results',
  ofac: {
    loading: 'Running OFAC check...',
    completed: 'OFAC check completed.',
  },
  forgotPassword: 'Forgot Password?',
  backToLogin: 'Back to Log in',
  submit: 'Submit',
  forgotPasswordMessage: 'If you have a SalesAssist account, an e-mail has been sent to you to reset your password.',
  documentName: 'Document Name',
  description: 'Descriptions',
  additionalRequirement: "Additional Requirements",
  uploadFormat: 'Upload a PDF',
  requireField: 'This field is required',
  passwordDoesNotMeetRequirement: 'The password you entered is invalid. Password must be at least eight characters long, in any combination of letters, numbers, and symbols.',
  passwordNotMatch: 'Oops, password doesn’t match.',
  nameFieldRequired: '{{name}} is a required field',
  fieldIsRequired: 'This field is required.',
  invalidEmail: 'Invalid email',
  login: 'Log In',
  verify: 'Verify',
  resend: 'Resend',
  "two-fact": {
    title: "2-factor Authentication",
    'update-title': "Update 2-factor Mobile Number",
    add: "Add a 2-factor Mobile Phone to Your Account",
    "resend-code": "Code has expired, you can resend now.",
    "code-sent": 'Code has been sent to {{phone}} and will be valid for 1 minute. You can resend in {{time}}s.',
    "wrong-code": "Wrong verification code, try again.",
    error: "Wrong verification code, try again",
    "code-sent-ending": "Code has been sent to phone number ending in {{phone}} and will be valid for 1 minute. You can resend in {{time}}s.",
    'do-not-ask': "Do not ask me for code on this device for 30 days",
    "success": "2-factor mobile number has been updated",
  },
  security: 'Security',
  selectCategory: 'Select a Category',
  freeFormFeedBack: 'Freeform Feedback',
  shareFeedback: 'my feedback .....',
  feedbackThaks: 'Thanks for your feedback/bug report. We’ll address it ASAP.',
  description: 'Description',
  popUpMsg: 'Please make sure the browser allows pop-ups from the CRM.',
  learnMore: 'Learn More',
  addCobuyer: 'Add a Cobuyer',
  cobuyerName: 'Cobuyer Name',
  error: 'Error',
  creatingLeadErrorMsg: 'Error adding participant to lead',
  creatingParticipantErrorMsg: 'Error creating new participant',
  success: 'Success',
  cobuyerSuccessMsg: 'Co-buyer added successfully',
  code: 'Code',
  insuranceVerification: 'Insurance Verification',
  companyName: 'Company Name',
  address: 'Address',
  nameInsured: 'Name Insured',
  policy: 'Policy No.',
  salesPersonCalling: 'Sales Person calling',
  dateCalled: 'Date Called',
  timeCalled: 'Time Called',
  personContacted: 'Person Contacted',
  submit: 'Submit',
  payoffVerification: 'Payoff Verification',
  customerName: 'Customer Name',
  uploadTradeIn: "Upload Trade-in Title Photo",
  carFinancedBy: 'Car Financed By',
  accountNumber: 'Account Number',
  netPayoff: 'Net Payoff',
  goodUntil: 'Good Until',
  perDiem: 'Per Diem',
  spokeTo: 'Spoke To',
  verifiedBy: 'Verified By',
  yes: 'Yes',
  no: 'No',
  checklist: {
    'shareable': {
      upload: 'Showroom Upload Docs',
      remote: 'Remote {{action}}',
    },
    'trade-in': {
      description: 'Does the deal have a Trade-in?',
    },
    'co-signer': {
      description: 'Does the deal have a co-signer?',
    },
    changeChecklistDefaultMessage: 'Are you sure you want to change the checklist type for this opportunity?',
    primaryVehicleCheckMessage: 'The primary vehicle of this opportunity is a {{vehicleType}} vehicle.'
  },
  soldCarVerification: 'Sold Car Verification',
  vehicleCondition: 'Vehicle Condition',
  odometer: 'Odometer',
  batteryCheck: 'Battery Check',
  uploadOdometerPhoto: 'Upload Odometer Photo',
  uploadBatteryPhoto: 'Upload Battery Check Photo',
  uploadFrontLicensePlate: 'Upload Front License Plate',
  uploadBackLicensePlate: 'Upload Back License Plate',
  vinNumber: 'VIN Number',
  pdiSlip: 'PDI Slip',
  numberofKeys: '# of Keys',
  payoff: 'Payoff',
  payoffStatus: 'Payoff Status',
  noPayoff: 'No Payoff',
  expirationDate: 'Expiration Date',
  closeOpp: `This is a {{lead}}. Please click on the Active Lead in the CRM (if any) and then open SalesAssist Extension.`,
  skip: 'Skip',
  licenseTitle: 'Do you want to update the customer record in the CRM with the address on this driver’s license?',
  addressTitle: 'What address to use for this deal?',
  cancelText: 'Cancel',
  changeTo: 'Change to',
};
